import React from "react";
import "./assets/css/style.scss";
import { Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import { Fade } from "react-awesome-reveal";
import ReviewsDetail from "./reviewsDetail.js";
import ReviewsBanner from "./reviewsBanner.js";
import { SocialBox } from "../../layout/menu/menu.js";
import ReactHtmlParser from "react-html-parser";
export default function Reviews({
  story_detail_01,
  story_detail_2,
  story_title_01,
  story_title_02,
  story_media_01,
  story_media_02,
}) {
  return (
    <div className="reviews">
      <div className="content">
        <Row className="check">
          <ReviewsDetail
            story_detail_01={story_detail_01}
            story_title_01={story_title_01}
          />
          <ReviewsBanner img={story_media_01} />
        </Row>
        <Row className="mt-lg-5 pt-lg-5 check2">
          <Col xs={6} className="review-img">
            <Fade direction="left">
              <div>
                <img src={story_media_02} alt="reviewer picture" />
              </div>
            </Fade>
          </Col>
          <Col xs={6} className="pt-3">
            <Fade direction="top">
              <div>
                <h2 className="text-left">{story_title_02}</h2>
                <p>{ReactHtmlParser(story_detail_2)}</p>
                <div className="partner">
                  <h5>Start your path to parenthood</h5>
                  {/* <Link to="/signup">Join for free</Link> */}
                  <SocialBox />
                </div>
              </div>
            </Fade>
          </Col>
        </Row>
      </div>
    </div>
  );
}
