import React from "react";

export default function expertInfo(props) {
  return (
    <div className="Expertinfo">
      <img src={props.img} alt="expert's image" />
      <h4>{props.title}</h4>
      <p>{props.info}</p>
    </div>
  );
}
