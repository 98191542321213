import React from "react";
import { Col } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";

export default function ReviewsBanner({ img }) {
  return (
    <Col xs={6} className="review-img overflow-adjust">
      <Fade direction="right">
        <div>
          <img src={img} alt="reviewer picture" />
        </div>
      </Fade>
    </Col>
  );
}
