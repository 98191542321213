import React from "react";
import { Col } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import ReactHtmlParser from "react-html-parser";
export default function ReviewsDetail({ story_detail_01, story_title_01 }) {
  return (
    <Col md={6} className="overflow-adjust pt-5 check">
      <Fade direction="top">
        <div>
          <h2>{story_title_01}</h2>
          <p>{ReactHtmlParser(story_detail_01)}</p>
        </div>
      </Fade>
    </Col>
  );
}
