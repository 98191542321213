import React from "react";
import "./assets/css/style.scss";
import { Row, Col } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";

export default function Heading({ heading, subheading }) {
  return (
    <div className="work">
      <div className="work-heading">
        <div className="content">
          <Row className="m-0">
            <Col xs={12}>
              <Fade>
                <h1>{heading}</h1>
                <h3>{subheading}</h3>
              </Fade>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
